/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../providers/AccountsProvider';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  ButtonGroup,
  Circle,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import AppColors from '../../constants/AppColors';
import { FormCQSelect, TextInput } from '@laxmimanogna/code-quick-components';
import SelectFormModal from './forms/SelectFormModal';
import FormMultiSelectModal from '../../components/form/FormMultiSelect';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { EditIcon, LinkIcon } from '../../constants/IconData';
import { sendMail } from '../../utils/send_mail.util';
import { EditAccountContact } from './components/EditAccountContactModal';
import { separateIdNames, separateSpecalityIdNames } from '../../utils';
import { toTitleCase } from '../../utils/common.utils';
import StateDropDown from '../../components/StateDropDown';
import { STATES } from '../../constants/constants';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 4px lightGrey',
    width: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '4px',
  },
};

function EditHealthSystem({ profileData, accountId, updateHsState }) {
  const [editingKey, setEditingKey] = useState(null);
  const [healthSystemData, setHealthSystemData] = useState({});
  const [updatedHealthSystemData, setUpdatedhealthSystemData] = useState({});

  const accountsContext = useContext(AccountContext);
  //   schema
  const schema = yup.object({
    insurance: yup.array(),
    ehr: yup.array(),
    specialty: yup.array(),
  });

  // useform
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      insurance: [],
      ehr: [],
      specialty: [],
    },
  });

  const { control, handleSubmit, reset,formState:{errors} } = form;

  useEffect(() => {
    if (Object.keys(profileData).length) {
      // if data
      const ehr = profileData.ehr?.length ? profileData.ehr.map(e => e.id) : [];
      const insurance = profileData.insurance?.length
        ? profileData.insurance.map(e => e.id)
        : [];
      const specialty = profileData.specialty?.length
        ? profileData.specialty.map(e => e.id)
        : [];

      setHealthSystemData({
        ...profileData,
        ehr,
        insurance,
        specialty,
        account_contact: profileData.account_contacts,
      });
      setUpdatedhealthSystemData({
        ...profileData,
        ehr,
        insurance,
        specialty,
        account_contact: profileData.account_contacts,
      });

      updateHsState(profileData.address);

      reset({
        ehr,

        insurance,

        specialty,
      });
    }
  }, [profileData]);

  // udated data
  const update = async (key, formData) => {
    const healthSystemBody = {
      name: updatedHealthSystemData.name,
      address: updatedHealthSystemData.address.split(",")[1],
      account_contact: updatedHealthSystemData.account_contact,
      ehr: separateIdNames(
        updatedHealthSystemData.ehr,
        'new_ehr_name',
        profileData.ehr
      ),
      insurance: separateIdNames(
        updatedHealthSystemData.insurance,
        'new_ins_name',
        profileData.insurance
      ),
      specialties: separateSpecalityIdNames(
        updatedHealthSystemData.specialty,
        'new_spec_name',
        profileData.specialty
      ),
      prefix: profileData.prefix,
    };
    if (key === 'ehr') {
      healthSystemBody[key] = separateIdNames(
        formData[key],
        'new_ehr_name',
        profileData.ehr
      );
    } else if (key === 'insurance') {
      healthSystemBody[key] = separateIdNames(
        formData[key],
        'new_ins_name',
        profileData.insurance
      );
    } else if (key === 'specialty') {
      healthSystemBody['specialties'] = separateSpecalityIdNames(
        formData[key],
        'new_spec_name',
        profileData.specialty
      );
    } else if (key === 'account_contact') {
      healthSystemBody[key] = formData[key];
    }
    await accountsContext.updateHealthSystemInformation(
      accountId,
      healthSystemBody
    );
  };

  const onUpdateData = key => {
    update(key);
    setHealthSystemData(prev => ({
      ...prev,
      [key]: updatedHealthSystemData[key],
    }));

    if(key==="address"){
      updateHsState(updatedHealthSystemData[key])
    }

    setEditingKey(null);
  };

  const onUndoUpdateData = key => {
    setUpdatedhealthSystemData(prev => ({
      ...prev,
      [key]: healthSystemData[key],
    }));
    setEditingKey(null);
  };

  const onMultiTextSubmit = (formData, key) => {
    update(key, formData);
    setHealthSystemData(prev => ({ ...prev, [key]: formData[key] }));
    setEditingKey(null);
  };

  const onEdit = key => {
    if (editingKey === key) {
      setEditingKey(null);
    } else {
      setEditingKey(key);
    }
  };

  const renderLeftData = (key, form) => {
    return (
      <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={4}>
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CheckIcon color={'black'} boxSize={3} />}
          onClick={() => {
            if (form) {
              handleSubmit(onMultiTextSubmit)(key);
              setEditingKey(null);
            } else {
              onUpdateData(key);
            }
          }}
        />
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<SmallCloseIcon color={'black'} boxSize={3} />}
          onClick={() => {
            onUndoUpdateData(key);
          }}
        />
      </ButtonGroup>
    );
  };

  // flexJoint
  const renderTextData = (key, inputType) => {
    return editingKey === key ? (
      <>
        <HStack width={'100%'}>
          <TextInput
            name={key}
            width={'250px'}
            id={{ key }}
            type={inputType}
            value={updatedHealthSystemData[key]}
            onChange={e => {
              setUpdatedhealthSystemData(prev => ({
                ...prev,
                [key]: e.target.value,
              }));
            }}
          />
          {renderLeftData(key)}
        </HStack>
      </>
    ) : (
      <Text color={AppColors.black} fontSize={'md'}>
        {healthSystemData[key]}
      </Text>
    );
  };
  const renderStateDropDown = (key) => {
    if (editingKey === 'address'&& Object.keys(updatedHealthSystemData).length) {
      const abbreviation = updatedHealthSystemData["address"]?.split(",")[1].trim()
      const healthsystemState =STATES.find(state => state.value === abbreviation)
     
      return (
        <>
          <FormCQSelect
            control={control}
            options={STATES}
            value={healthsystemState}
            styles={{
              control: styles => ({
                ...styles,
                height: '50px',
                borderRadius: '5px',
                border: `1px solid rgba(0,0,0,0.1)`,
              }),
            }}
            id={'state'}
            name={'state'}
            onChange={e => {
              setUpdatedhealthSystemData(prev => ({
                ...prev,
                [key]: e.label,
              }));

              
            }}
            placeholder="Select State"
            variant="primary"
            isSearchable={true}
          />
          {renderLeftData(key)}
        </>
      );
    }else{
    return (
      <Text color={AppColors.black} fontSize={'md'}>
        {healthSystemData[key]}
      </Text>
    );
    }
     
  };

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  function renderInsuranceForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Insurance'}
        addNewText={'Other Insurance Name'}
        subTitle={'Select Insurance'}
        isFetching={accountsContext.isFetchingInsurance}
        isCreating={accountsContext.isCreatingInsurance}
        onCreateNewOption={accountsContext.createInsuranceOption}
      />
    );
  }

  function renderSpecialtyForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Specialties'}
        addNewText={'Other Specialty Name'}
        subTitle={'Select Specialty'}
        isFetching={accountsContext.isFetchingSpecialtyOption}
        isCreating={accountsContext.isCreatingSpecialtyOption}
        onCreateNewOption={accountsContext.createSpecialtyOption}
      />
    );
  }

  const renderMultiForm = key => {
    const title = {
      ehr: { option: 'ehrOptions', form: renderEHRFormModal },
      insurance: {
        option: 'insuranceOptions',
        form: renderInsuranceForModal,
      },
      specialty: {
        option: 'specialtyOptions',
        form: renderSpecialtyForModal,
      },
    };
    const options = accountsContext[title[key]['option']];
    if(key === "specialty"){
      profileData?.specialty?.forEach(item1 => {
        if (!options.some(item2 => item2.id === item1.id)) {
          options.push({
                label: item1.name,
                value: item1.id,
                id: item1.id
            });
        }
      });         
    }
    return editingKey === key ? (
      <HStack>
        <Box>
          <FormMultiSelectModal
            control={control}
            name={key}
            placeholder={'None'}
            options={options}
            renderFormModal={title[key]['form']}
          />
        </Box>
        {renderLeftData(key, true)}
      </HStack>
    ) : (
      <Stack>
        <Text color={AppColors.black} fontSize={'md'}>
          {healthSystemData[key]?.length
            ? healthSystemData[key].map((id, index) => {
                if (options.find(d => d.value === id)?.label) {
                  return `${options.find(d => d.value === id)?.label} ${
                    index < healthSystemData[key].length - 1 ? ' | ' : ''
                  }`;
                }
              })
            : 'N/A'}
        </Text>
      </Stack>
    );
  };

  const renderContactForm = () => {
    return healthSystemData['account_contact']?.length ? (
      healthSystemData['account_contact']
        .sort((acc, next) => Number(next.is_primary) - Number(acc.is_primary))
        .map(account => {
          return (
            <HStack>
              {account.is_primary ? (
                <Circle
                  borderColor={AppColors.gray}
                  borderWidth={1}
                  boxSize={5}
                >
                  <CheckIcon color={AppColors.primary} boxSize={3} />
                </Circle>
              ) : (
                <Circle boxSize={5} />
              )}
              <HStack>
                <Text fontSize={'sm'} color={AppColors.black}>
                  {toTitleCase(account.first_name)}
                  {toTitleCase(account.last_name)} -
                </Text>
                <Text fontSize={'sm'} color={AppColors.primary}>
                  {account.email}
                </Text>
                <LinkIcon
                  onClick={() => {
                    sendMail(account.email);
                  }}
                  style={{ height: 15, width: 15, cursor: 'pointer' }}
                />
              </HStack>
            </HStack>
          );
        })
    ) : (
      <Text>N/A</Text>
    );
  };

  const health_system_data = [
    {
      heading: 'Health System Name',
      label: 'name',
      type: 'text',
      component: 'text',
    },
    {
      heading: 'State',
      label: 'address',
      type: 'dropDown',
      component: 'stateDropdown',
    },
    {
      heading: 'Insurance',
      label: 'insurance',
      type: 'multi',
      component: 'modal',
    },
    {
      heading: 'EHR',
      label: 'ehr',
      type: 'multi',
      component: 'modal',
    },
    {
      heading: 'Specialties',
      label: 'specialty',
      type: 'multi',
      component: 'modal',
    },
  ];

  const renderFormComponent = (component, label, type) => {
    if (component === 'modal') {
      return renderMultiForm(label);
    } else if (component === 'text') {
      return renderTextData(label, type);
    } else if (component === 'stateDropdown') {
      return renderStateDropDown(label);
    }
  };

  const renderLeftDataColumn = () => {
    return health_system_data.map(({ heading, label, type, component }) => {
      return (
        <Stack
          key={`${label}-component`}
          mt={4}
          p={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Flex justifyContent={'space-between'}>
            <Box justifyContent={'space-around'} width={'100%'}>
              <Text
                color={AppColors.secondary}
                fontSize={'md'}
                fontWeight="bold"
                mb={2}
              >
                {heading}
              </Text>
              {renderFormComponent(component, label, type)}
              {/* {component === 'modal'
                ? renderMultiForm(label)
                : renderTextData(label, type)} */}
            </Box>
            {editingKey !== label && label !== 'specialty' ? (
              <Circle
                size="40px"
                bg={AppColors.gray}
                onClick={() => onEdit(label)}
                cursor={'pointer'}
              >
                <EditIcon />
              </Circle>
            ) : null}
          </Flex>
        </Stack>
      );
    });
  };

  return (
    <Box width={'100%'} mb={4} p={5} borderRadius={'lg'}>
      {renderLeftDataColumn()}

      {/* Account Contact */}
      <Stack mt={4}>
        <HStack justifyContent={'space-between'} alignItems="baseline">
          <Box>
            <Text
              color={AppColors.secondary}
              fontSize={'md'}
              fontWeight="bold"
              mb={3}
            >
              Account Contact
            </Text>
          </Box>
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('account_contact')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        </HStack>
        <Stack sx={scrollStyle} maxHeight={'200px'} overflowY={'scroll'}>
          {renderContactForm('account_contact')}
        </Stack>
      </Stack>

      <EditAccountContact
        isOpen={editingKey === 'account_contact'}
        onClose={() => setEditingKey(null)}
        onContactChange={formData => {
          onMultiTextSubmit(formData, 'account_contact');
        }}
        accountData={
          healthSystemData ? healthSystemData['account_contact'] : []
        }
      />
    </Box>
  );
}

export default React.memo(EditHealthSystem);
